.checkmarkButton {
  background-color: #c4c5c4;
  background-image: linear-gradient(rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.08));
  border-radius: 0 3px 3px 0;
  padding: 1rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.4s linear;
}

.checkmarkButton:hover {
  background-color: #4daf7c;
  background-image: linear-gradient(rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.08));
}

.headingWrapper {
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rail {
  width: 20rem;
  box-shadow: inset 2px 0 5px -2px rgb(0 0 0 / 14%);
  padding: 1rem;
}

.railButton {
  font-size: 0.8rem;
}

.rail label {
  display: block;
}

.rail :global(.field) {
  margin: 1rem 0;
}

.railInputWrapper,
.railInput {
  width: 100%;
}

.railSelect {
  width: 100%;
}

.messagePlaceholder {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: left;
}