.header {
  padding: 1.5rem;
  box-shadow: 2px 3px 5px rgb(0 0 0 / 8%);
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  position: fixed;
  width: 100vw;
  background-color: white;
}

.headerWrapper {
  height: 68px;
  position: relative;
}

.content {
  margin: auto;
  display: flex;
  justify-content: space-between;
}
