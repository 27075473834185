/*******************************
         Site Overrides
*******************************/

.ui[class*="right labeled"].input input:focus {
  border-right-color: transparent;
}

.ui.compact.input input {
  padding: @compactVerticalPadding @compactHorizontalPadding;
}

.ui.icon.input > i.calendar.icon {
  top: -1px;
  opacity: 0.3;
}

.ui[class*="top attached"].input input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ui[class*="bottom attached"].input input {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/* BUG - Fixes transparent input cutoff */
.ui.transparent.input input {
  border-radius: 0px;
}

/* Need some arbitrary sized input thats not fitted */
.ui.tight.input input {
  padding: @7px @9px;
}

/* Subtle Styling */
.ui.subtle.input input:not(:hover):not(:focus) {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none
}
.ui.subtle.input input:hover:not(:focus) {
  background-color: transparent;
  border-color: #efefef;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.07) inset
}
.ui.subtle.header.input input {
  font-size: 18px;
  padding: 7px 8px;
  font-weight: bold;
}
