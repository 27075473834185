.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main {
  display: flex;
  flex: 1;
  background-color: #f5f6f6;
}
