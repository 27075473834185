.footer {
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconAndCopyright {
  display: flex;
  align-items: center;
}

.copyright {
  margin-left: 1rem;
  color: rgba(0, 0, 0, 0.4);
}

.link {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 1rem;
}
