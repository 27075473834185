.container {
  position: fixed;
  top: 80px;
  right: 14px;
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.06), rgba(0, 0, 0, 0.06));
  min-width: 290px;
  min-height: 72px;
  border-radius: 5px;
  z-index: 999999;
  animation: ease-in-out 700ms flyInLeft;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container:hover {
  cursor: pointer;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  padding: 25px;
  font-weight: 700;
  gap: 4px;
}

/* Leave it at last frame of 0 opacity */
.hide {
  animation: 400ms leaves;
  animation-fill-mode: forwards;
}

@keyframes leaves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@font-face {
  font-family: 'FlatIcon';
  src: url('./Flaticon.woff2') format('woff2'), url('./Flaticon.woff') format('woff');
}

.icon {
  padding-top: 10px;
  padding-left: 14px;
}

.warning {
  background-color: #fffdf4;
}
.warning .messageHeader {
  color: #c19206;
}
.warning .messageBody {
  color: rgba(165, 145, 42, 0.84);
}
.warning .icon:after {
  font-family: FlatIcon;
  content: '\e00f';
  color: #c1a306;
  font-size: 40px;
}

.info {
  background-color: #f4fdff;
}
.info .messageHeader {
  color: #0d5d7b;
}
.info .messageBody {
  color: rgba(13, 93, 123, 0.84);
}
.info .icon:after {
  font-family: FlatIcon;
  content: '\e073';
  color: rgba(13, 93, 123, 0.5);
  font-size: 40px;
}

.error {
  background-color: #fff4f4;
}
.error .messageHeader {
  color: #c10b06;
}
.error .messageBody {
  color: rgba(154, 31, 28, 0.75);
}
.error .icon:after {
  font-family: FlatIcon;
  content: '\e0d3';
  color: #c10b06;
  font-size: 40px;
}

.success {
  background-color: #fff;
}
.success .messageHeader {
  color: #277651;
}
.success .messageBody {
  color: rgba(39, 118, 81, 0.75);
}
.success .icon:after {
  font-family: FlatIcon;
  content: '\e029';
  color: #d0dcd6;
  font-size: 40px;
}

.messageHeader {
  font-size: 16px;
  line-height: 1.4285em;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.messageBody {
  font-size: 14px;
  line-height: 1.333;
}

@keyframes flyInLeft {
  0% {
    opacity: 0;
    transform: translate3d(1500px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
