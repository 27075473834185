/*******************************
         Site Overrides
*******************************/
html, body, body > #root {
  min-height: 100vh;
}

body > #root {
  display: flex;
  flex-direction: column;
}

html {
  text-rendering: optimizeLegibility;
}

/* Force Simple Scrollbars */
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 9px;
}

body ::-webkit-scrollbar-track {
  border-radius: 5px;
  background:rgba(0, 0, 0, 0.1);
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  transition: color 0.2s ease;
  background: rgba(0, 0, 0, 0.25);
}

body ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

body ::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}

/* Blockquotes */
blockquote {
  margin: 0em;
  border-left: 3px solid #DDD;
  display: block;
  padding: 0.5em 1em 0.25em;
}

/* List */
ul {
  padding-left: 1em;
}
ul:first-child {
  margin-top: 0em;
}
ul:last-child {
  margin-bottom: 0em;
}


/*******************************
             Fonts
*******************************/
