.page {
  background-image: linear-gradient(180deg, #000, #0c272f 45%, #000);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
}

.footer {
  height: 200px;
  background-repeat: no-repeat;
  background-position: bottom;
}
