/*******************************
         Site Overrides
*******************************/

/* Tooltip on loading button */
.ui.loading.button[data-tooltip]:before,
.ui.loading.button[data-tooltip]:after {
  box-shadow: none;
  transform: none;
  background: none transparent;
  transform-origin: initial;
  padding: 0px;
  visibility: visible;
  transition: none;
  opacity: 1;
}

/* Fix icon overlap issue */
.ui.circular.button > .icon {
  width: 1.1em;
  margin: 0 -0.05em;
  vertical-align: baseline;
}

.ui.clickable.disabled.disabled.disabled.disabled.disabled.disabled.disabled.disabled.disabled.disabled.button {
  pointer-events: auto;
  cursor: not-allowed;
}

.ui.fluid.fluid.fluid.labeled.icon.button {
  padding-left: @horizontalPadding;
  padding-right: @horizontalPadding;
  text-align: center;
}

/* More brand colors */
.ui.google.button {
  background-color: #DB4437;
  color: #FFFFFF;
}
.ui.microsoft.button {
  background-color: #00A1F1;
  color: #FFFFFF;
}
.ui.yahoo.button {
  background-color: #410093;
  color: #FFFFFF;
}

/* Fix labeled button popup issue */
.ui.labeled.button > .label {
  display: flex;
}

/* Icon box shadow */
.ui.labeled.icon.buttons > .button > .icon,
.ui.labeled.icon.button > .icon {
  box-shadow:
    -1px 0px 0px 0px rgba(0, 0, 0, 0.1) inset,
    1px 0px 2px rgba(0, 0, 0, 0.06)
  ;
}
.ui[class*="right labeled"].icon.buttons > .button > .icon,
.ui[class*="right labeled"].icon.button > .icon {
  box-shadow:
    1px 0px 0px 0px rgba(0, 0, 0, 0.1) inset,
    1px 0px 2px rgba(0, 0, 0, 0.06)
  ;
}
.ui.labeled.icon.button:not(.primary):not(.secondary):not(.negative):not(.positive) > .icon {
  background-color: rgba(135, 141, 146, 0.14);
}

/* Dont allow selection of button text */
.ui.button {
  -webkit-user-select: none;
}

/* Prevent box shadow from basic button being removed by button group rules */
.ui.buttons > .ui.basic.button {
  box-shadow: @basicBoxShadow;
}

/* Delete icon button */
.ui.delete.icon.button > .delete.icon {
  transition: 0.3s color ease;
}
.ui.delete.icon.button:hover > .delete.icon {
  color: @red;
}

/* Labeled button */
.ui.labeled.button > .button + .basic.label {
  box-shadow: 0px 0px 0px 1px rgba(29, 36, 39, 0.25) inset;
  border-color: transparent;
  border-width: 0px;
}

/* Upsize button icons */
.ui.button:not(.large):not(.big):not(.huge):not(.massive) > .icon:not(.button) {
  font-size: 14px;
  vertical-align: -1px;
}
.ui.button:not(.large):not(.big):not(.huge):not(.massive) > .dropdown.icon:not(.button) {
  vertical-align: -2px;
  font-size: 13px;
}

/* Downsize button icons */
.ui.mini.button.button.button.button.button.button {
  font-size: 12px;
  padding: @5px @8px;
  text-transform: none;
}
.ui.mini.button.button.button.button.button.button > .icon:not(.button) {
  font-size: 12px;
  vertical-align: -1px;
}

.ui.button i.icons .icon {
  opacity: 1;
}

.ui.next.button {
  float: right;
}

.ui.button:not(.icon) > .add.icon {
  margin: 0px 0px 0px 4px;
}

/* Button group focus */
/*
.ui.buttons > .button:focus {
  box-shadow:
    0 -1px 0 0 rgba(0, 0, 0, 0.05) inset,
    0 0 0 1px #4DA55D inset,
    0 1px 3px rgba(0, 0, 0, 0.15)
  ;
}
.ui.buttons > .button:focus + .button {
  box-shadow:
    0 -1px 0 0 rgba(0, 0, 0, 0.05) inset,
    -1px 0px 0px 0px #4DA55D,
    0 0 0 1px rgba(0, 0, 0, 0.13) inset,
    0 1px 3px rgba(0, 0, 0, 0.15)
  ;
}
*/

/* Custom Red Basic Button Style */
.ui.basic.red.buttons .button,
.ui.basic.red.button {
  box-shadow: @basicBoxShadow;
  background-color: @basicBackground;
}
.ui.basic.red.buttons .button:hover,
.ui.basic.red.button:hover {
  box-shadow: @basicHoverBoxShadow;
  background-color: @basicHoverBackground;
}
.ui.basic.red.buttons .button:focus,
.ui.basic.red.button:active {
  box-shadow: @basicDownBoxShadow;
  background-color: @basicFocusBackground;
}
.ui.basic.red.buttons .button:active,
.ui.basic.red.button:active {
  box-shadow: @basicDownBoxShadow;
  background-color: @basicDownBackground;
}

/* Disabled with Popup */
.ui.disabled.button[data-tooltip],
.ui.disabled.button[data-content] {
  cursor: not-allowed;
  pointer-events: auto;
}


.ui.fluid.buttons {
  margin-right: 0px;
}
.ui.fluid.buttons .dropdown.button {
  flex: 0 1 auto;
}

/* Action input icon offset */
.ui.action.input > .ui.button > .icon {
  margin-top: -1px;
}

/* Very compact */
.ui[class*="very compact"].button {
  padding: @relative9px @relative12px;
}

/* Fix basic down inheritance */
.ui.basic.buttons .button:active,
.ui.basic.button:active {
  box-shadow: @basicDownBoxShadow;
}

/*--- Info ---*/
.ui.info.buttons .button,
.ui.info.button {
  background-color: @infoTextColor;
  color: @white;
  text-shadow: none;
  background-image: @coloredBackgroundImage;
}
.ui.info.button {
  box-shadow: @coloredBoxShadow;
}
.ui.info.buttons .button:hover,
.ui.info.button:hover {
  background-color: lighten(@infoTextColor, 5);
  color: @white;
  text-shadow: none;
}
.ui.info.buttons .button:focus,
.ui.info.button:focus {
  background-color: darken(@infoTextColor, 5);
  color: @white;
  text-shadow: none;
}
.ui.info.buttons .button:active,
.ui.info.button:active {
  background-color: darken(@infoTextColor, 8);
  color: @white;
  text-shadow: none;
}
.ui.info.buttons .active.button,
.ui.info.buttons .active.button:active,
.ui.info.active.button,
.ui.info.button .active.button:active {
  background-color: darken(@infoTextColor, 3);
  color: @white;
  text-shadow: none;
}

/*--- Warning ---*/
.ui.warning.buttons .button,
.ui.warning.button {
  background-color: @orange;
  color: @orangeTextColor;
  text-shadow: @orangeTextShadow;
  background-image: @coloredBackgroundImage;
}
.ui.warning.button {
  box-shadow: @coloredBoxShadow;
}
.ui.warning.buttons .button:hover,
.ui.warning.button:hover {
  background-color: @orangeHover;
  color: @orangeTextColor;
  text-shadow: @orangeTextShadow;
}
.ui.warning.buttons .button:focus,
.ui.warning.button:focus {
  background-color: @orangeFocus;
  color: @orangeTextColor;
  text-shadow: @orangeTextShadow;
}
.ui.warning.buttons .button:active,
.ui.warning.button:active {
  background-color: @orangeDown;
  color: @orangeTextColor;
  text-shadow: @orangeTextShadow;
}
.ui.warning.buttons .active.button,
.ui.warning.buttons .active.button:active,
.ui.warning.active.button,
.ui.warning.button .active.button:active {
  background-color: @orangeActive;
  color: @orangeTextColor;
  text-shadow: @orangeTextShadow;
}

/* Error */
.ui.error.buttons .button,
.ui.error.button {
  background-color: @negativeColor;
  color: @negativeTextColor;
  text-shadow: @negativeTextShadow;
  background-image: @coloredBackgroundImage;
}
.ui.error.button {
  box-shadow: @coloredBoxShadow;
}
.ui.error.buttons .button:hover,
.ui.error.button:hover {
  background-color: @negativeColorHover;
  color: @negativeTextColor;
  text-shadow: @negativeTextShadow;
}
.ui.error.buttons .button:focus,
.ui.error.button:focus {
  background-color: @negativeColorFocus;
  color: @negativeTextColor;
  text-shadow: @negativeTextShadow;
}
.ui.error.buttons .button:active,
.ui.error.button:active {
  background-color: @negativeColorDown;
  color: @negativeTextColor;
  text-shadow: @negativeTextShadow;
}
.ui.error.buttons .active.button,
.ui.error.buttons .active.button:active,
.ui.error.active.button,
.ui.error.button .active.button:active {
  background-color: @negativeColorActive;
  color: @negativeTextColor;
  text-shadow: @negativeTextShadow;
}


/* Secondary basic button */
.ui.secondary.basic.buttons .button,
.ui.secondary.basic.button {
  background-color: @secondaryColor;
  color: @secondaryTextColor;
  text-shadow: @secondaryTextShadow;
}
.ui.secondary.basic.button {
  box-shadow: @secondaryBoxShadow;
}
.ui.secondary.basic.buttons .button:hover,
.ui.secondary.basic.button:hover {
  background-color: @secondaryColorHover;
  color: @secondaryTextColor;
  text-shadow: @secondaryTextShadow;
}
.ui.secondary.basic.buttons .button:focus,
.ui.secondary.basic.button:focus {
  background-color: @secondaryColorFocus;
  color: @secondaryTextColor;
  text-shadow: @secondaryTextShadow;
}
.ui.secondary.basic.buttons .button:active,
.ui.secondary.basic.button:active {
  background-color: @secondaryColorDown;
  color: @secondaryTextColor;
  text-shadow: @secondaryTextShadow;
}
.ui.secondary.basic.buttons .active.button,
.ui.secondary.basic.active.button {
  background-color: @secondaryColorActive;
  color: @secondaryTextColor;
  text-shadow: @secondaryTextShadow;
}

/* Primary basic button */
.ui.primary.basic.buttons .button,
.ui.primary.basic.button {
  background-color: @primaryColor;
  color: @primaryTextColor;
  text-shadow: @primaryTextShadow;
}
.ui.primary.basic.button {
  box-shadow: @primaryBoxShadow;
}
.ui.primary.basic.buttons .button:hover,
.ui.primary.basic.button:hover {
  background-color: @primaryColorHover;
  color: @primaryTextColor;
  text-shadow: @primaryTextShadow;
}
.ui.primary.basic.buttons .button:focus,
.ui.primary.basic.button:focus {
  background-color: @primaryColorFocus;
  color: @primaryTextColor;
  text-shadow: @primaryTextShadow;
}
.ui.primary.basic.buttons .button:active,
.ui.primary.basic.button:active {
  background-color: @primaryColorDown;
  color: @primaryTextColor;
  text-shadow: @primaryTextShadow;
}
.ui.primary.basic.buttons .active.button,
.ui.primary.basic.active.button {
  background-color: @primaryColorActive;
  color: @primaryTextColor;
  text-shadow: @primaryTextShadow;
}
.ui.buttons .disabled.disabled.disabled.button {
  opacity: 1;
  text-shadow: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
  background-color: #F7F7F7;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05) inset, 0 0 0 1px rgba(0, 0, 0, 0.08) inset, 0 1px 3px rgba(0, 0, 0, 0.05);
  color: #D8D8D8;
}

/* Compact */
.ui.basic.compact.button {
  font-weight: bold;
}
.ui.basic.compact.button > .icon {
  font-size: 16px;
  vertical-align: top;
  width: auto;
  opacity: 0.4;
}
.ui.basic.compact.button:not(.icon) > .icon {
  margin-right: 7px;
}
.ui.basic.compact.button:not(.secondary):not(.primary):not(.icon) > .icon {
  color: #556267;
}

.ui.basic.compact.button.active > .icon,
.ui.basic.compact.button:hover > .icon {
  opacity: 1;
}
.ui.basic.compact.button:last-child {
  margin-right: 0px;
}

/* Disabled */
.ui.disabled.disabled.disabled.button:not(.basic) {
  opacity: 1;
  text-shadow: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
  background-color: #ffffff;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05) inset, 0 0 0 1px rgba(0, 0, 0, 0.08) inset, 0 1px 3px rgba(0, 0, 0, 0.05);
  color: rgb(220, 220, 220);
}
.ui.buttons > .ui.disabled.disabled.disabled.button:not(.basic) {
  background-color: #F0F0F0;
}
.ui.secondary.buttons:not(.basic) .disabled.disabled.disabled.button,
.ui.disabled.disabled.disabled.secondary.button:not(.basic) {
  opacity: 1;
  text-shadow: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
  background-color: #ccd0d2;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05) inset, 0 0 0 1px rgba(0, 0, 0, 0.08) inset, 0 1px 3px rgba(0, 0, 0, 0.05);
  color: #9fa6a9;
}
.ui.positive.buttons:not(.basic) .disabled.disabled.disabled.button,
.ui.positive.disabled.disabled.disabled.button:not(.basic),
.ui.primary.buttons:not(.basic) .disabled.disabled.disabled.button,
.ui.primary.disabled.disabled.disabled.button:not(.basic) {
  opacity: 1;
  text-shadow: none;
  background-color: #E2E8E4;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.05));
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05) inset, 0 0 0 1px rgba(0, 0, 0, 0.08) inset, 0 1px 3px rgba(0, 0, 0, 0.05);
  color: rgba(137, 162, 142, 0.6);
}

.ui.basic.disabled.disabled.disabled.button {
  opacity: 1;
  color: rgba(0, 0, 0, 0.2);
  background: @basicBackground;
  box-shadow: @basicBoxShadow;
}
.ui.secondary.basic.disabled.disabled.disabled.button {
  opacity: 1;
  color: rgba(255, 255, 255, 0.2);
  background: @secondaryColor;
  box-shadow: @basicBoxShadow;
}


/* At presseds styles for mobile */
.ui.button.pressed,
.ui.buttons > .button.pressed {
  background-color: @downBackgroundColor;
  background-image: @downBackgroundImage;
  color: @downColor;
  box-shadow: @downBoxShadow;
}

/* Add New Simplified Positive / Negative Button Styles */
.ui.subtle.negative.buttons .button,
.ui.subtle.negative.button {
  background-color: @subtleNegativeColor;
  color: @subtleNegativeTextColor;
  text-shadow: @subtleNegativeTextShadow;
  background-image: @subtleNegativeBackgroundImage;
}
.ui.subtle.negative.button {
  box-shadow: @coloredBoxShadow;
}
.ui.subtle.negative.buttons .button:hover,
.ui.subtle.negative.button:hover {
  background-color: @subtleNegativeColorHover;
  color: @subtleNegativeTextColor;
  text-shadow: @subtleNegativeTextShadow;
}
.ui.subtle.negative.buttons .button:focus,
.ui.subtle.negative.button:focus {
  background-color: @subtleNegativeColorFocus;
  color: @subtleNegativeTextColor;
  text-shadow: @subtleNegativeTextShadow;
}
.ui.subtle.negative.buttons .button:active,
.ui.subtle.negative.button:active {
  background-color: @subtleNegativeColorDown;
  color: @subtleNegativeTextColor;
  text-shadow: @subtleNegativeTextShadow;
}
.ui.subtle.negative.buttons .active.button,
.ui.subtle.negative.buttons .active.button:active,
.ui.subtle.negative.active.button,
.ui.subtle.negative.button .active.button:active {
  background-color: @subtleNegativeColorActive;
  color: @subtleNegativeTextColor;
  text-shadow: @subtleNegativeTextShadow;
}
.ui.subtle.negative.buttons .button > .icon,
.ui.subtle.negative.button > .icon {
  color: @subtleNegativeTextColor;
}

 /* Negative Labeled Icon */
.ui.subtle.negative.buttons .labeled.icon.button > .icon,
.ui.subtle.negative.labeled.icon.button > .icon {
  background-color: rgba(241, 206, 206, 0.2);
}

.ui.subtle.positive.buttons .button,
.ui.subtle.positive.button {
  background-color: @subtlePositiveColor;
  color: @subtlePositiveTextColor;
  text-shadow: @subtlePositiveTextShadow;
  background-image: @subtlePositiveBackgroundImage;
}
.ui.subtle.positive.button {
  box-shadow: @coloredBoxShadow;
}
.ui.subtle.positive.buttons .button:hover,
.ui.subtle.positive.button:hover {
  background-color: @subtlePositiveColorHover;
  color: @subtlePositiveTextColor;
  text-shadow: @subtlePositiveTextShadow;
}
.ui.subtle.positive.buttons .button:focus,
.ui.subtle.positive.button:focus {
  background-color: @subtlePositiveColorFocus;
  color: @subtlePositiveTextColor;
  text-shadow: @subtlePositiveTextShadow;
}
.ui.subtle.positive.buttons .button:active,
.ui.subtle.positive.button:active {
  background-color: @subtlePositiveColorDown;
  color: @subtlePositiveTextColor;
  text-shadow: @subtlePositiveTextShadow;
}
.ui.subtle.positive.buttons .active.button,
.ui.subtle.positive.buttons .active.button:active,
.ui.subtle.positive.active.button,
.ui.subtle.positive.button .active.button:active {
  background-color: @subtlePositiveColorActive;
  background-image: @subtlePositiveBackgroundImage;
  color: @subtlePositiveTextColor;
  text-shadow: @subtlePositiveTextShadow;
}
.ui.subtle.positive.buttons .button > .icon,
.ui.subtle.positive.button > .icon {
  color: @subtlePositiveTextColor;
}

 /* Subtle Warning Buttons */
.ui.subtle.warning.buttons .button,
.ui.subtle.warning.button {
  background-color: @subtleWarningColor;
  color: @subtleWarningTextColor;
  text-shadow: @subtleWarningTextShadow;
  background-image: @subtleWarningBackgroundImage;
}
.ui.subtle.warning.button {
  box-shadow: @coloredBoxShadow;
}
.ui.subtle.warning.buttons .button:hover,
.ui.subtle.warning.button:hover {
  background-color: @subtleWarningColorHover;
  color: @subtleWarningTextColor;
  text-shadow: @subtleWarningTextShadow;
}
.ui.subtle.warning.buttons .button:focus,
.ui.subtle.warning.button:focus {
  background-color: @subtleWarningColorFocus;
  color: @subtleWarningTextColor;
  text-shadow: @subtleWarningTextShadow;
}
.ui.subtle.warning.buttons .button:active,
.ui.subtle.warning.button:active {
  background-color: @subtleWarningColorDown;
  color: @subtleWarningTextColor;
  text-shadow: @subtleWarningTextShadow;
}
.ui.subtle.warning.buttons .active.button,
.ui.subtle.warning.buttons .active.button:active,
.ui.subtle.warning.active.button,
.ui.subtle.warning.button .active.button:active {
  background-color: @subtleWarningColorActive;
  color: @subtleWarningTextColor;
  text-shadow: @subtleWarningTextShadow;
}
.ui.subtle.warning.buttons .button > .icon,
.ui.subtle.warning.button > .icon {
  color: @subtleWarningTextColor;
}

.ui.subtle.positive.buttons .labeled.icon.button > .icon,
.ui.subtle.positive.labeled.icon.button > .icon {
  background-color: @subtleWarningLabeledIconColor;
}

/*
  Designed for selection with active item emphasized
*/
.ui.selection.buttons {
  border-radius: @borderRadius;
}
.ui.selection.buttons .ui.button {
  background: @white;
  box-shadow: none;
  font-size: 12px;
  font-weight: normal;
  border: 1px solid @solidBorderColor;
  color: @unselectedTextColor;
  transition:
    border-color @defaultDuration @defaultEasing,
    color @defaultDuration @defaultEasing
  ;
}
.ui.selection.buttons .ui.button:not(.active):hover {
  color: @selectedTextColor;
}
.ui.selection.buttons .ui.active.button {
  position: relative;
  background-color: #F6FFFA;
  font-weight: bold;
  border-color: #86D3AC;
  color: #43976C;
}
.ui.vertical.selection.buttons .ui.button:not(:first-child) {
  margin-top: -1px;
}

/* Ghost / Simple Styling */
.ui.simple.buttons > .ui.button,
.ui.simple.button {
  color: #717171;
  font-weight: bold;
  border: 1px solid @solidBorderColor;
  background: transparent;
  box-shadow: none;
}
.ui.simple.buttons > .ui.button:hover,
.ui.simple.button:hover {
  color: @textColor;
  border-color: 1px solid @solidBorderColor;
  background-color: rgba(0, 0, 0, 0.02);
}
.ui.simple.buttons > .ui.button > i.icon,
.ui.simple.button > i.icon {
  font-size: 1rem;
  opacity: 0.4;
}

/* Grouped but not touching */
.ui.separate.buttons > .button {
  border-radius: @borderRadius;
}
.ui.separate.buttons > .button + .button {
  margin-left: @5px;
}

/* Transparent */
.ui.transparent.button {
  background-image: @transparentBackgroundImage;
  background-color: @transparentBackgroundColor;
  color: @transparentTextColor;
  box-shadow: @transparentBoxShadow;
}
.ui.transparent.button:hover {
  background-image: @transparentHoverBackgroundImage;
  background-color: @transparentHoverBackgroundColor;
  color: @transparentHoverTextColor;
  box-shadow: @transparentHoverBoxShadow;
}
.ui.transparent.button:active {
  background-image: @transparentDownBackgroundImage;
  background-color: @transparentDownBackgroundColor;
  color: @transparentDownTextColor;
  box-shadow: @transparentDownBoxShadow;
}
.ui.transparent.button.active {
  background-image: @transparentActiveBackgroundImage;
  background-color: @transparentActiveBackgroundColor;
  color: @transparentActiveTextColor;
  box-shadow: @transparentActiveBoxShadow;
}

/* Transparent Inverted */
.ui.transparent.inverted.button {
  background-image: @transparentInvertedBackgroundImage;
  background-color: @transparentInvertedBackgroundColor;
  color: @transparentInvertedTextColor;
  box-shadow: @transparentInvertedBoxShadow;
}
.ui.transparent.inverted.button:hover {
  background-image: @transparentInvertedHoverBackgroundImage;
  background-color: @transparentInvertedHoverBackgroundColor;
  color: @transparentInvertedHoverTextColor;
  box-shadow: @transparentInvertedHoverBoxShadow;
}
.ui.transparent.inverted.button.active {
  background-image: @transparentInvertedActiveBackgroundImage;
  background-color: @transparentInvertedActiveBackgroundColor;
  color: @transparentInvertedActiveTextColor;
  box-shadow: @transparentInvertedActiveBoxShadow;
}

/* Ghost Button */
.ui.ghost.button {
  display: inline-block;
  cursor: pointer;
  color: #647177;
  font-weight: bold;
  padding: 0.8rem 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  transition: background 0.3s ease;
  margin-right: 0rem;
  background: rgba(0, 0, 0, 0.0);
  border: none;
  box-shadow: none;
}

.ui.ghost.button:hover {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  box-shadow: none;
}
.ui.ghost.button:active {
  background: rgba(0, 0, 0, 0.1);
  border: none;
  box-shadow: none;
}
