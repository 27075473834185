.form {
  width: 100%;
  margin-right: 2rem;
}

.form label {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
}

.textArea {
  padding: 0.5rem;
  width: 100%;
}

.input {
  width: 300px;
}


.modalContent {
  display: flex;
}

.formField {
  width: 100%;
}