.formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.form {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.formActions {
  width: 30rem;
}

.errorMessage {
  display: flex;
  align-items: center;
}

.messagePlaceholder {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form :global(.ui.input) {
  display: block;
  flex: 1;
  width: 30rem;
}

.form :global(.ui.input > input) {
  background: hsla(0, 0%, 100%, 0.1);
  color: hsla(0, 0%, 100%, 0.9);
  box-shadow: none;
  display: block;
  width: 100%;
}

.form :global(.ui.input > input:focus) {
  background: hsla(0, 0%, 100%, 0.2);
  color: hsla(0, 0%, 100%, 0.9);
  border-color: transparent;
}

.form :global(.field) {
  margin: 0.25rem 0;
}

.form :global(.ui.button) {
  margin-top: 2rem;
}

.form label {
  display: block;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
}

.loginButton {
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.logo {
  margin-bottom: 2rem;
}
